var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "lipdub-mission", attrs: { column: "", "fill-height": "" } },
    [
      _vm.isHost && !!_vm.audio
        ? [
            !_vm.audioTrackUrl
              ? _c(
                  "v-btn",
                  {
                    staticClass: "play-stop-btn",
                    attrs: {
                      icon: "",
                      color: "success",
                      loading: _vm.initializing
                    },
                    on: { click: _vm.play }
                  },
                  [_c("v-icon", [_vm._v("play_arrow")])],
                  1
                )
              : _c(
                  "v-btn",
                  {
                    staticClass: "play-stop-btn",
                    attrs: {
                      color: "success",
                      icon: "",
                      loading: _vm.initializing
                    },
                    on: { click: _vm.stop }
                  },
                  [_c("v-icon", [_vm._v("stop")])],
                  1
                )
          ]
        : _vm._e(),
      _c(
        "v-flex",
        {
          ref: "root",
          staticClass: "mission-instructions",
          attrs: { "d-flex": "" }
        },
        [
          _c(
            "transition",
            { attrs: { name: "mission-instructions-transition" } },
            [
              _c("ResizableText", {
                key: "lipdub-instructions-chunk-" + _vm.lyrics,
                class: "mission-instructions-chunk chunk-" + _vm.lyrics,
                attrs: {
                  message: _vm.currentLyrics,
                  transition: false,
                  update: false
                }
              })
            ],
            1
          )
        ],
        1
      ),
      !!_vm.lipdubTrack
        ? _c("UserAudio", {
            key: "lipdub-channel-" + _vm.currentMissionID,
            attrs: { track: _vm.lipdubTrack }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }