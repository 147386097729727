<template>
  <v-layout column fill-height class="lipdub-mission">
    <template v-if="isHost && !!audio">
      <v-btn
        v-if="!audioTrackUrl"
        class="play-stop-btn"
        icon
        color="success"
        @click="play"
        :loading="initializing"
      >
        <v-icon>play_arrow</v-icon>
      </v-btn>
      <v-btn
        v-else
        color="success"
        class="play-stop-btn"
        icon
        @click="stop"
        :loading="initializing"
      >
        <v-icon>stop</v-icon>
      </v-btn>
    </template>
    <v-flex d-flex class="mission-instructions" ref="root">
      <transition name="mission-instructions-transition">
        <ResizableText
          :key="`lipdub-instructions-chunk-${lyrics}`"
          :class="`mission-instructions-chunk chunk-${lyrics}`"
          :message="currentLyrics"
          :transition="false"
          :update="false"
        />
      </transition>
    </v-flex>
    <UserAudio
      v-if="!!lipdubTrack"
      :track="lipdubTrack"
      :key="`lipdub-channel-${currentMissionID}`"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import ResizableText from "./ResizableText.vue"
import { TweenMax, Back } from "gsap/TweenMax"
import { TwilioRoom } from "@/services/twilio.service"
import { AudioTrack } from "@/services/audio.service"
import { fetchTwilioToken } from "@/services/api.service"
import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"
import { LIPDUB_CHANNEL_IDENTIFIER } from "@/store/TwilioModule"

import {
  MODULE_NAME as SOUND_EFFECT_MODULE_NAME,
  GetterTypes
} from "@/store/SoundEffectModule"

const wrapWithSpan = string =>
  `<span style="display:inline-block;white-space:pre;">${string}</span>`

const splitIntoWords = string => string.match(/\S+\s*/g) || []

const serialize = string => String(string).trim()

let connection = null
/** @type {(AudioTrack|null)} */
let audioTrack = null

export default {
  name: "Lipdub",
  components: {
    ResizableText,
    UserAudio
  },
  data() {
    return {
      audioTrackUrl: null,
      initializing: false,
      twilioToken: null
    }
  },
  methods: {
    play() {
      this.audioTrackUrl = this.audio
    },
    stop() {
      this.audioTrackUrl = null
    },
    terminate() {
      this.stop()
      if (audioTrack && audioTrack.stop) audioTrack.stop()
      if (connection && connection.disconnect) connection.disconnect()
    },
    updateAudioElementVolume(value) {
      if (this.$refs.media && this.$refs.media.$el)
        this.$refs.media.$el.volume = value
    }
  },
  watch: {
    volume: {
      /**
       * @param {number} value
       */
      handler(value) {
        this.updateAudioElementVolume(value)
      },
      immediate: true
    },
    audioTrackUrl: {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) return
        this.initializing = true
        if (newValue) {
          try {
            audioTrack = new AudioTrack({ url: newValue })
            connection = await TwilioRoom.connectToRoom({
              token: this.twilioToken,
              name: this.gameID,
              audioTrack: audioTrack.get()
            })
            await audioTrack.play()
          } catch (e) {
            console.warn(e.message)
          }
        } else {
          this.terminate()
        }
        this.initializing = false
      }
    },
    lyrics(value) {
      this.$nextTick(() => {
        const elements = document.querySelectorAll(`.chunk-${value} span`)
        TweenMax.staggerFrom(
          elements,
          0.3,
          {
            delay: 0.45,
            opacity: 0,
            y: 30,
            ease: Back.easeOut.config(2)
          },
          0.1
        )
      })
    }
  },
  computed: {
    ...mapGetters({
      getLyricsIndex: "getLipdubMissionLyricsIndex",
      currentMission: "getCurrentMission"
    }),
    ...mapGetters(["user", "gameID"]),
    ...mapGetters("auth", ["isHost", "token"]),
    ...mapGetters("twilio", ["lipdubTrack"]),
    volume() {
      return this.$store.getters[
        `${SOUND_EFFECT_MODULE_NAME}/${GetterTypes.VOLUME}`
      ]
    },
    lyrics() {
      return this.getLyricsIndex(this.currentMissionID) || 0
    },
    instructions() {
      return this.currentMission.instructions.split(";").map(serialize)
    },
    instructionsHtml() {
      return this.instructions.map(string =>
        splitIntoWords(string)
          .map(wrapWithSpan)
          .join("")
      )
    },
    currentLyrics() {
      return this.instructionsHtml[this.lyrics]
    },
    audio() {
      return this.currentMission ? this.currentMission.audio : null
    },
    currentMissionID() {
      return this.currentMission.theKey
    }
  },
  async created() {
    this.initializing = true
    this.$store.dispatch("subscribeToLipdub", {
      missionID: this.currentMissionID
    })
    if (this.isHost) {
      this.twilioToken = await fetchTwilioToken({
        token: this.token,
        userID: LIPDUB_CHANNEL_IDENTIFIER
      })
    }
    this.initializing = false
  },
  beforeDestroy() {
    this.terminate()
    this.$store.dispatch("unsubscribeFromLipdub")
  }
}
</script>

<style lang="scss">
.lipdub-mission {
  .mission-instructions-transition-enter-active,
  .mission-instructions-transition-leave-active {
    transition: all ease 0.6s;
    transform: translateY(0);
    opacity: 1;
  }
  .mission-instructions-transition-enter-active {
    transition-delay: 0.3s;
  }
  .mission-instructions-transition-enter {
    transform: translateY(80px);
  }
  .mission-instructions-transition-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  }
  .mission-instructions-chunk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .mission-instructions {
    position: relative;
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .play-stop-btn {
    position: absolute;
    z-index: 99;
    margin: 0;
  }
  .audio-element {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}
</style>
