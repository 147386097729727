import { render, staticRenderFns } from "./Lipdub.vue?vue&type=template&id=1f2915e8&"
import script from "./Lipdub.vue?vue&type=script&lang=js&"
export * from "./Lipdub.vue?vue&type=script&lang=js&"
import style0 from "./Lipdub.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFlex,VIcon,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/finnkelly/Code/Go Remote/remote-chat-finn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f2915e8')) {
      api.createRecord('1f2915e8', component.options)
    } else {
      api.reload('1f2915e8', component.options)
    }
    module.hot.accept("./Lipdub.vue?vue&type=template&id=1f2915e8&", function () {
      api.rerender('1f2915e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/Lipdub.vue"
export default component.exports