class AudioTrack {
  constructor({ url, loop = false, volume, onComplete = function() {} }) {
    if (!url) throw new Error("No source is given")
    this.audio = new Audio(url)
    if (loop) this.audio.loop = true
    if (volume) this.audio.volume = volume
    this.audio.crossOrigin = "anonymous"
    this.onComplete = onComplete
    const ctx = new (window.AudioContext || window.webkitAudioContext)()
    const destination = ctx.createMediaStreamDestination()
    const source = ctx.createMediaElementSource(this.audio)
    source.connect(destination)
    const { stream } = destination
    const [track] = stream.getAudioTracks()
    this.track = track
    return this
  }
  get() {
    return this.track
  }
  play() {
    if (this.audio) {
      this.audio.play()
      this.audio.addEventListener("ended", this.onComplete)
    } else {
      throw new Error("No audio to play")
    }
  }
  stop() {
    this.audio.removeEventListener("ended", this.onComplete)
    if (this.audio && this.audio.stop) this.audio.stop()
    if (this.audio && this.audio.load) this.audio.load()
    if (this.track && this.track.stop) this.track.stop()
  }
}

export { AudioTrack }
